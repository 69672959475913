import { Injectable } from '@angular/core';

import { Account } from 'src/models/accounts';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends GenericObjectsService<Account> {
  protected baseUrl = '/accounts';
}
