import { Injectable } from '@angular/core';

import { KnowledgeBaseArticle } from 'src/models';

import { GenericObjectsService } from './generic-objects.service';

@Injectable({
  providedIn: 'root',
})
export class KnowledgeBaseArticlesService extends GenericObjectsService<KnowledgeBaseArticle> {
  protected baseUrl = '/knowledge-base/articles';

  bulkDelete(articleIds: string[]): Promise<{ success: boolean }> {
    return this.apiService.deletePromise(`${this.baseUrl}/bulk`, { articleIds });
  }
}
