import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth.guard';
import { CompanyPrivacyGuard } from './company-privacy.guard';
import { CloseWindowComponent } from './components/close-window/close-window.component';
import { SuperAdminGuard } from './super-admin.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'profiles/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'people/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'people/:type/:profileId',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'login/:authProvider',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthPageModule),
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
    canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'integrations/:integrationType',
    loadChildren: () => import('./integrations/integrations.module').then((m) => m.IntegrationsPageModule),
    // canActivate: [CompanyPrivacyGuard, AuthGuard],
  },
  {
    path: 'super-admin',
    loadChildren: () => import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'slack-message',
    loadChildren: () => import('./slack-message/slack-message.module').then((m) => m.SlackMessagePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'accounts',
    loadComponent: () => import('./accounts/accounts-list/accounts-list.page').then((m) => m.AccountsListPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'channels',
    loadComponent: () => import('./channels/channel-list/channel-list.page').then((m) => m.ChannelListPage),
    canActivate: [AuthGuard],
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customer-list/customer-list.module').then((m) => m.CustomerListPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'customers/new',
    loadChildren: () =>
      import('./customers/customer-create/customer-create.module').then((m) => m.CustomerCreatePageModule),
  },
  {
    path: 'channels/new',
    loadChildren: () =>
      import('./customers/customer-create/customer-create.module').then((m) => m.CustomerCreatePageModule),
  },
  {
    path: 'customer-admin',
    loadChildren: () =>
      import('./customers/customer-admin/customer-admin.module').then((m) => m.CustomerAdminPageModule),
  },
  {
    path: 'upgrade',
    loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'subscription',
    loadChildren: () => import('./upgrade/upgrade.module').then((m) => m.UpgradePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./cx-home/cx-home.module').then((m) => m.CxHomeModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
  },
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'install',
    loadChildren: () => import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'submit-survey-response',
    loadComponent: () =>
      import('./components/survey-response/survey-response.component').then((m) => m.SurveyResponseComponent),
  },
  {
    path: 'close-window',
    component: CloseWindowComponent,
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
