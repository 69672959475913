import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { v4 as uuidv4 } from 'uuid';

import {
  CUSTOM_FIELD_TYPE_SHORT_ANSWER,
  CUSTOM_FIELD_TYPE_LONG_ANSWER,
  CUSTOM_FIELD_TYPE_CHECKBOX,
  CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
  CUSTOM_FIELD_TYPE_SINGLE_SELECT,
  CUSTOM_FIELD_TYPE_MULTI_SELECT,
  CUSTOM_FIELD_TYPE_USER_SELECT,
  CUSTOM_FIELD_TYPE_MULTI_USER_SELECT,
  CUSTOM_FIELD_TYPE_DATE,
  CUSTOM_FIELD_TYPE_TEXT,
} from 'src/app/constants';
import { flattenAdditionalIdpFields } from 'src/app/utils/utils';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';

import { ActionDropdownComponent, ActionDropdownType } from '../action-dropdown/action-dropdown.component';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss'],
})
export class CustomFieldsComponent implements OnInit {
  @Input() customFields: any[] = [];
  @Input() addCustomFieldButtonLabel: string;
  @Input() placeholder: string;
  @Input() showPublicOption: boolean;
  @Input() showUserAttributeOption: boolean;
  @Input() showBranchingInput: boolean;
  @Input() showDescription: boolean;
  @Input() contextPrefix: string;

  dropdownOptionFields: string[] = [];

  customFieldTypes = [
    {
      displayName: 'Short answer',
      key: CUSTOM_FIELD_TYPE_SHORT_ANSWER,
    },
    {
      displayName: 'Long answer',
      key: CUSTOM_FIELD_TYPE_LONG_ANSWER,
    },
    {
      displayName: 'Checkbox',
      key: CUSTOM_FIELD_TYPE_CHECKBOX,
    },
    {
      displayName: 'Single select',
      key: CUSTOM_FIELD_TYPE_SINGLE_SELECT,
    },
    {
      displayName: 'Multi select',
      key: CUSTOM_FIELD_TYPE_MULTI_SELECT,
    },
    {
      displayName: 'User select',
      key: CUSTOM_FIELD_TYPE_USER_SELECT,
    },
    {
      displayName: 'Multi user select',
      key: CUSTOM_FIELD_TYPE_MULTI_USER_SELECT,
    },
    {
      displayName: 'Date',
      key: CUSTOM_FIELD_TYPE_DATE,
    },
    {
      displayName: 'Text',
      key: CUSTOM_FIELD_TYPE_TEXT,
    },
    // {
    //   displayName: 'File upload',
    //   key: CUSTOM_FIELD_TYPE_FILE_UPLOAD,
    // },
  ];

  externalSelectFieldLabels: Record<string, Record<string, string>> = {};

  userAttributes: any = [];

  constructor(
    private authService: AuthService,
    private popoverCtrl: PopoverController,
    private searchablePopoverService: SearchablePopoverService,
    private apiService: ApiService,
  ) {}

  async ngOnInit() {
    if (this.showUserAttributeOption) {
      this.customFieldTypes.push({
        displayName: 'User attribute',
        key: CUSTOM_FIELD_TYPE_USER_ATTRIBUTE,
      });

      if (this.authService.profile.additionalIdpFields) {
        this.userAttributes = Object.keys(flattenAdditionalIdpFields(this.authService.profile.additionalIdpFields));
      }
    }

    await this.loadExternalSelectFieldLabels();
  }

  addCustomField() {
    if (!this.customFields) {
      this.customFields = [];
    }

    // Cool random arbitrary limit on length
    if (this.customFields.length >= 100) {
      return;
    }

    this.customFields.push({
      label: '',
      description: '',
      // value: '',
      type: CUSTOM_FIELD_TYPE_SHORT_ANSWER,
      required: false,
      public: true,
      id: uuidv4(),
      conditions: {
        type: 'simple',
        simple: {},
      },
    });
  }

  async reorderField(index: number) {
    const MOVE_UP = 'Move up';
    const MOVE_DOWN = 'Move down';

    const options: ActionDropdownType[] = [];

    if (index !== 0) {
      options.push({
        label: MOVE_UP,
        icon: 'arrow-up-outline',
      });
    }

    if (index < this.customFields.length - 1) {
      options.push({
        label: MOVE_DOWN,
        icon: 'arrow-down-outline',
      });
    }

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (_index: number, label: string) => {
          popover.dismiss();

          const item = this.customFields.splice(index, 1)[0];
          const newIndex = label === MOVE_UP ? index - 1 : index + 1;
          this.customFields.splice(newIndex, 0, item);
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  deleteCustomField(index: number) {
    this.customFields.splice(index, 1);
  }

  async showCustomFieldOptions(index: number) {
    const COPY_ID = 'Copy input field ID';
    const DELETE = 'Delete input field';

    const options: ActionDropdownType[] = [];

    const customField = this.customFields[index];

    if (customField.id) {
      options.push({
        label: COPY_ID,
        icon: 'copy-outline',
      });
    }

    options.push({
      label: DELETE,
      icon: 'trash-outline',
      destructive: true,
    });

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: (_index: number, label: string) => {
          popover.dismiss();

          if (label === COPY_ID) {
            navigator.clipboard
              .writeText(customField.id)
              .then(() => {})
              .catch(() => {
                // Unable to copy
              });
          } else if (label === DELETE) {
            this.deleteCustomField(index);
          }
        },
      },
      showBackdrop: false,
      event,
    });
    popover.present();
  }

  addCustomFieldOption(fieldIndex: number, label: string) {
    if (!label) {
      return;
    }

    if (!this.customFields[fieldIndex].options) {
      this.customFields[fieldIndex].options = [label];
    } else {
      this.customFields[fieldIndex].options.push(label);
    }

    this.dropdownOptionFields = [];
  }

  deleteCustomFieldOption(fieldIndex: number, optionIndex: number) {
    this.customFields[fieldIndex].options.splice(optionIndex, 1);
  }

  toggleConditionsShown(fieldIndex: number) {
    const field = this.customFields[fieldIndex];

    if (this.getConditionsForField(field)?.length) {
      field.conditions.simple[this.getConditionsType(field)] = [];
    } else {
      this.addSimpleCondition(fieldIndex);
    }
  }

  addSimpleCondition(fieldIndex: number) {
    const field = this.customFields[fieldIndex];

    if (!this.getConditionsForField(field)?.length) {
      field.conditions = {
        type: 'simple',
        simple: {
          and: [],
        },
      };
    }

    field.conditions.simple[this.getConditionsType(field)] = [
      ...this.getConditionsForField(field),
      {
        leftValueFrom: '',
        operator: 'equals',
        rightValue: null,
      },
    ];
  }

  removeSimpleCondition(fieldIndex: number, conditionIndex: number) {
    const field = this.customFields[fieldIndex];

    field.conditions.simple[this.getConditionsType(field)].splice(conditionIndex, 1);
  }

  onSimpleConditionFieldChange(event: any, condition: any) {
    // Reset the value when the field changes
    condition.rightValue = null;
  }

  getFieldType(fieldLabel: string): string {
    const field = this.customFields.find((f) => this.getFieldIdPath(f.id) === fieldLabel);

    return field ? field.type : 'text';
  }

  async showExternalSelectOptions(fieldLabel: string, condition: any) {
    const field = this.customFields.find((f) => this.getFieldIdPath(f.id) === fieldLabel);
    const isMultiSelect = [CUSTOM_FIELD_TYPE_MULTI_SELECT, CUSTOM_FIELD_TYPE_MULTI_USER_SELECT].includes(field.type);
    const onSaveCallback = (id: string, label: string) => {
      let value: string | string[] | null = null;

      if (isMultiSelect) {
        value = Array.from(new Set<string>(condition.rightValue ?? []).add(id));
      } else {
        if (id !== condition.rightValue) {
          value = id;
        }
      }

      condition.rightValue = value;

      if (field.externalSelect != null) {
        const valueArr = Array.isArray(value) ? value : [value];

        for (const v of valueArr) {
          if (v === id) {
            this.externalSelectFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] = {
              ...(this.externalSelectFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] ?? {}),
              [`${field.externalSelect}:${v}`]: label,
            };
          }
        }
      }
    };

    this.searchablePopoverService.customFieldOptions({
      event,
      field,
      context: {},
      callback: onSaveCallback,
    });
  }

  async getFieldOptions(fieldLabel: string): Promise<{ label: string; value: string }[]> {
    const field = this.customFields.find((f) => this.getFieldIdPath(f.id) === fieldLabel);

    return field && (field.type === 'single-select' || field.type === 'multi-select')
      ? field.options?.map((o) => {
          if (typeof o === 'string') {
            return {
              label: o,
              value: o,
            };
          }

          return o;
        })
      : [];
  }

  compareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }

  getConditionalFieldOptions(i: number) {
    return this.customFields.filter(
      (f, j) => f.type !== 'user-select' && f.type !== 'multi-user-select' && j !== i && f.type !== 'text',
    );
  }

  getFieldIdPath(id: string) {
    return `${this.contextPrefix}['${id}']`;
  }

  getConditionsForField(field: any) {
    return field.conditions?.simple?.[this.getConditionsType(field)] ?? [];
  }

  toggleConditionsType(field: any) {
    if (field.conditions?.simple?.and?.length) {
      field.conditions.simple.or = field.conditions.simple.and;
      field.conditions.simple.and = null;
    } else if (field.conditions?.simple?.or?.length) {
      field.conditions.simple.and = field.conditions.simple.or;
      field.conditions.simple.or = null;
    }
  }

  getConditionsType(field: any) {
    return field.conditions?.simple?.and ? 'and' : 'or';
  }

  onReorder({ detail }, item: any) {
    const { from, to } = detail;

    item.options.splice(to, 0, item.options.splice(from, 1)[0]);

    detail.complete();
  }

  trackValueBy(_i: number, value: { label: string; value: string }) {
    return value.value;
  }

  getExternalSelectFieldLabel(fieldLabel: string, value: string) {
    const field = this.customFields.find((f) => this.getFieldIdPath(f.id) === fieldLabel);

    if (field.externalSelect != null) {
      return (
        this.externalSelectFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT]?.[`${field.externalSelect}:${value}`] ?? value
      );
    }

    return value;
  }

  async loadExternalSelectFieldLabels() {
    const allConditions = this.customFields?.flatMap((f) => this.getConditionsForField(f)) ?? [];
    const userSelectFields = new Set<string>();
    const externalSelectFields = new Set<string>();

    for (const condition of allConditions) {
      const field = this.customFields.find((f) => this.getFieldIdPath(f.id) === condition.leftValueFrom);

      const valueArr = Array.isArray(condition.rightValue) ? condition.rightValue : [condition.rightValue];

      if ([CUSTOM_FIELD_TYPE_USER_SELECT, CUSTOM_FIELD_TYPE_MULTI_USER_SELECT].includes(field.type)) {
        for (const value of valueArr) {
          userSelectFields.add(value);
        }
      } else if (
        [CUSTOM_FIELD_TYPE_SINGLE_SELECT, CUSTOM_FIELD_TYPE_MULTI_SELECT].includes(field.type) &&
        field.externalSelect != null
      ) {
        for (const value of valueArr) {
          externalSelectFields.add(`${field.externalSelect}:${value}`);
        }
      }
    }

    if (userSelectFields.size || externalSelectFields.size) {
      const lookupValues: any = await this.apiService.postPromise('/saved-filters/get-lookup-values', {
        assigneeId: Array.from(userSelectFields),
        externalSelectValue: Array.from(externalSelectFields),
      });

      this.externalSelectFieldLabels[CUSTOM_FIELD_TYPE_USER_SELECT] = lookupValues.assigneeId;
      this.externalSelectFieldLabels[CUSTOM_FIELD_TYPE_SINGLE_SELECT] = lookupValues.externalSelectValue;
    }
  }

  removeMultiSelectOptionFromCondition(condition: any, value: string) {
    condition.rightValue = condition.rightValue.filter((o) => o !== value);
  }

  isExternalSelectField(field: any) {
    return (
      [CUSTOM_FIELD_TYPE_SINGLE_SELECT, CUSTOM_FIELD_TYPE_MULTI_SELECT].includes(field.type) &&
      field.externalSelect != null
    );
  }

  toggleExternalSelect(field: any) {
    if (field.externalSelect != null) {
      field.externalSelect = null;
    } else {
      field.externalSelect = '';
    }
  }

  showExternalSelectFunctions(field: any) {
    this.searchablePopoverService.externalSelectFunctions({
      event,
      callback: (id: string) => {
        field.externalSelect = id;
      },
    });
  }
}
