import { Injectable } from '@angular/core';

import { ListRequest, ListResponse, Customer, CommunityMember } from 'src/models';

import { ApiService } from './api.service';

const BASE_URL = '/customers';

interface UpsertCommunityMemberOptions {
  customerId: string;
  userId?: string;
  email?: string;
  phoneNumber?: string;
  name?: string;
  notes?: string;
  muteWelcomeEmail?: boolean;
  customFields?: any;
  following?: boolean;
  notifications?: any;
  isExternal?: boolean;
}

interface BulkUpdateCommunityMembersOptions {
  customerId: string;
  userIds: string[];
  following?: boolean;
  isExternal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CustomersService {
  constructor(private apiService: ApiService) {}

  create(name: string, slackChannelId?: string, projectId?: string): Promise<Customer> {
    return this.apiService.postPromise(`${BASE_URL}`, {
      name,
      slackChannelId,
      projectId,
    });
  }

  update(customerId: string, updates: any): Promise<void> {
    return this.apiService.patchPromise(`${BASE_URL}/${customerId}`, updates);
  }

  bulkCreate(
    channels: Record<string, string>,
    isInternalOverride?: boolean,
    teamId?: string,
    projectId?: string,
  ): Promise<string[]> {
    return this.apiService.postPromise(`${BASE_URL}/bulk`, {
      channels,
      isInternalOverride,
      teamId,
      projectId,
    });
  }

  list(options: ListRequest = {}): Promise<ListResponse<Partial<Customer>>> {
    return this.apiService.postPromise(`${BASE_URL}/list`, options);
  }

  get(customerId: string): Promise<Partial<Customer>> {
    return this.apiService.getPromise(`${BASE_URL}/${customerId}`);
  }

  async delete(customerId: string) {
    return this.apiService.deletePromise(`${BASE_URL}/${customerId}`);
  }

  async upsertCustomerMember({
    customerId,
    userId,
    email,
    phoneNumber,
    name,
    notes,
    muteWelcomeEmail,
    customFields,
    following,
    notifications,
    isExternal,
  }: UpsertCommunityMemberOptions) {
    return this.apiService.postPromise(`${BASE_URL}/${customerId}/members`, {
      userId,
      email,
      phoneNumber,
      name,
      following,
      notes,
      muteWelcomeEmail,
      customFields,
      notifications,
      isExternal,
    });
  }

  async bulkUpdateCustomerMembers({ customerId, userIds, following, isExternal }: BulkUpdateCommunityMembersOptions) {
    return this.apiService.patchPromise(`${BASE_URL}/${customerId}/members/bulk`, {
      userIds,
      following,
      isExternal,
    });
  }

  async getFollowers(customerId: string): Promise<CommunityMember[]> {
    const communityMembers: CommunityMember[] = (await this.apiService.getPromise(
      `${BASE_URL}/${customerId}/members`,
    )) as any;

    return communityMembers;
  }
}
