<ion-app mode="md">
  <ion-content *ngIf="authService.accountStatus === 'inactive'">
    <div class="flexed" style="height: 100%; justify-content: center; align-items: center">
      <ion-card style="width: 50%; padding: 20px; font-size: 18px">
        Your organization's account has been deactivated. If you believe this is a mistake or you would like to
        reactivate the account, reach out to us at
        <a href="mailto:help@unthread.io">help&#64;unthread.io</a>.
      </ion-card>
    </div>
  </ion-content>
  <ng-container *ngIf="authService.accountStatus !== 'inactive'">
    <ng-container *ngIf="!authService.isEmailVerified">
      <ion-content>
        <div class="flexed" style="height: 100%; justify-content: center; align-items: center; flex-direction: column">
          <ion-card style="max-width: 560px; padding: 20px; font-size: 18px; text-align: center">
            <ion-icon name="mail-unread-outline" style="font-size: 4.2rem; opacity: 0.7; margin-top: 20px"></ion-icon>
            <h1>Check your email</h1>
            We just sent a link to confirm your email address. Reach out to
            <a href="mailto:help@unthread.io">help&#64;unthread.io</a> with any questions.
            <div style="margin-top: 20px">
              <ion-button (click)="returnToLogin()" fill="clear">Return to login page</ion-button><br />
              <ion-button (click)="resendVerificationEmail()" fill="clear">Resend verification email</ion-button>
            </div>
          </ion-card>
        </div>
      </ion-content>
    </ng-container>

    <ng-container *ngIf="authService.isEmailVerified">
      <ion-header [hidden]="hideNavBar">
        <ion-toolbar class="nav-toolbar">
          <div class="nav-header">
            <div class="flexed">
              <ion-menu-toggle id="main-content" class="shown-lg">
                <ion-button shape="round" class="icon-button" fill="clear" color="dark">
                  <ion-icon name="menu-outline"></ion-icon>
                </ion-button>
              </ion-menu-toggle>
              <a routerLink="dashboard" routerDirection="root" class="logo-wrapper">
                <img src="{{ environment.assetsBaseUrl + '/imgs/icon-no-bg.png' }}" class="logo" />
              </a>
              <div *ngIf="isSuperAdmin" class="hidden-mobile">
                <a class="nav-link" routerDirection="root" routerLink="super-admin">
                  <ion-icon name="skull" class="nav-icon"></ion-icon>
                  <span class="nav-title">Super Admin</span>
                </a>
              </div>
            </div>
            <div>
              <ion-button
                shape="round"
                [routerLink]="'/upgrade'"
                routerDirection="root"
                style="margin-right: 8px"
                [color]="trialExpired ? 'danger' : 'primary'"
                [fill]="trialExpired ? 'solid' : 'clear'"
                *ngIf="trialStatus && authService.isTenantAdmin"
                ngxTippy
                [tippyProps]="{
                  touch: false,
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Click to upgrade"
                class="hidden-sm btn-small"
              >
                {{ trialStatus }}
              </ion-button>
              <ion-button
                shape="round"
                [routerLink]="'/upgrade'"
                routerDirection="root"
                class="btn-small"
                color="success"
                style="margin-right: 8px"
                *ngIf="trialStatus && authService.isTenantAdmin && !trialExpired"
              >
                <ion-icon name="arrow-up-circle" class="padded-icon"></ion-icon>
                Upgrade
              </ion-button>

              <ion-button
                class="btn-small icon-button add-button"
                shape="round"
                color="dark"
                (click)="showCreateOptions()"
                *ngIf="authService.user"
                id="global-create-btn"
              >
                <ion-icon name="add"></ion-icon>
              </ion-button>

              <ion-button
                (click)="openCommandBar()"
                class="icon-button"
                fill="clear"
                color="dark"
                detail="false"
                *ngIf="authService.user"
                ngxTippy
                [tippyProps]="{
                  arrow: false,
                  touch: false,
                  allowHTML: true,
                  placement: 'bottom',
                  content: 'Show shortcuts<br/><strong>⌘ 🅺</strong>'
                }"
              >
                <ion-icon name="flash"></ion-icon>
              </ion-button>
              <ion-button
                (click)="openSlack()"
                class="icon-button hidden-sm"
                fill="clear"
                color="dark"
                detail="false"
                *ngIf="authService.user"
                ngxTippy
                [tippyProps]="{
                  touch: false,
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Open Slack inbox"
              >
                <ion-icon name="logo-slack"></ion-icon>
              </ion-button>
              <ion-button
                (click)="openGiftOptions()"
                class="icon-button hidden-sm"
                fill="clear"
                color="dark"
                detail="false"
                *ngIf="authService.user"
                ngxTippy
                [tippyProps]="{
                  touch: false,
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Stuff just for you"
              >
                <ion-icon name="gift-outline"></ion-icon>
              </ion-button>
              <ion-button
                (click)="openNotifications()"
                class="icon-button"
                fill="clear"
                color="dark"
                detail="false"
                *ngIf="authService.user"
                ngxTippy
                [tippyProps]="{
                  touch: false,
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Notifications"
              >
                <ion-icon [name]="unreadNotifications ? 'notifications' : 'notifications-outline'"></ion-icon>
                <span class="notifications-bubble" *ngIf="unreadNotifications">{{ unreadNotifications }}</span>
              </ion-button>
              <ion-button fill="clear" (click)="loginOrSignup()" *ngIf="!authService.user">
                Log in or sign up
              </ion-button>
              <ion-button
                fill="clear"
                color="dark"
                (click)="openProfile()"
                *ngIf="authService.user"
                class="hidden-mobile"
                style="margin: 0"
              >
                <app-avatar
                  size="27"
                  [style.width]="'27px'"
                  [style.height]="'27px'"
                  [name]="authService.user.displayName"
                  [image]="authService.userPhoto"
                >
                </app-avatar>
                <span class="hidden-xl text-with-left-icon">{{ authService.user.displayName }}</span>
              </ion-button>
              <!-- Hacky flag to share admin status to CommandBar -->
              <div id="_nav-is-admin" *ngIf="authService.isTenantAdmin"></div>
            </div>
          </div>
        </ion-toolbar>
        <!-- <ion-toolbar *ngIf="trialExpired">
          <div class="upgrade-feature-banner">
            <p style="margin-top: 0; font-weight: 700">Your trial has expired</p>
            <ion-button
              fill="outline"
              color="light"
              class="btn-small"
              shape="round"
              [routerLink]="'/upgrade'"
              *ngIf="authService.isTenantAdmin"
            >
              <ion-icon name="arrow-up-circle" class="padded-icon"></ion-icon>
              Upgrade account
            </ion-button>
            <ion-button
              fill="outline"
              color="light"
              class="btn-small"
              shape="round"
              href="mailto:sales@unthread.io"
              *ngIf="authService.isTenantAdmin"
            >
              Talk to sales
            </ion-button>
          </div>
        </ion-toolbar> -->
      </ion-header>
      <ion-content>
        <ion-router-outlet id="main-content" [environmentInjector]="environmentInjector"></ion-router-outlet>
      </ion-content>
      <ion-footer [hidden]="hideNavBar || !authService.user" class="shown-mobile">
        <ion-toolbar *ngIf="authService.user">
          <div class="nav-header">
            <a
              *ngFor="let p of appPages; let i = index"
              class="nav-link"
              routerDirection="root"
              [routerLink]="[p.url]"
              routerLinkActive="selected"
              #rla="routerLinkActive"
            >
              <ion-icon [name]="rla.isActive ? p.icon : p.icon + '-outline'" class="nav-icon"></ion-icon>
              <span class="nav-title">{{ p.title }}</span>
              <ion-icon
                *ngIf="p.private"
                name="lock-closed-outline"
                class="padded-icon-left hidden-xl"
                ngxTippy
                [tippyProps]="{
                  arrow: false,
                  placement: 'bottom'
                }"
                data-tippy-content="Only visible to admins"
              ></ion-icon>
            </a>
            <a
              class="nav-link"
              routerDirection="root"
              [routerLink]="'/profile'"
              routerLinkActive="selected"
              #rla="routerLinkActive"
            >
              <div class="profile-photo">
                <app-avatar [size]="36" [name]="authService.user.displayName" [image]="authService.userPhoto">
                </app-avatar>
              </div>
            </a>
          </div>
        </ion-toolbar>
      </ion-footer>
    </ng-container>
  </ng-container>
</ion-app>
